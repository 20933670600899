import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import fb from "../../assets/icons/fb.svg";
import insta from "../../assets/icons/insta.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import twitter from "../../assets/icons/twitter.svg";
import purple_b from "../../assets/purple_b.svg";
import purple_name from "../../assets/purple_name.svg";
import { Base_url } from "../../utils/utils";
import useWindowDimensions from "../../utils/windowDimension";
import {
  useGetFooterLinksItemsQuery,
  useGetFooterNewsLinksQuery
} from "../../redux/api/footerApi";

// import {
//   EmailShareButton,
//   FacebookShareButton,
//   LinkedinShareButton,
//   TwitterShareButton,
// } from "react-share";
// import mailBlack from "../../assets/icons/mailBlack.svg";
import styles from "./Footer.module.css";
const Footer = () => {
  const { height, width } = useWindowDimensions();
  const [mobile, setMobile] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null)
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const {
    data: footerLinksData,
    isLoading: footerLinksLoading,
    error: footerLinksError,
  } = useGetFooterLinksItemsQuery();


  const {
    data: newsLinksData,
    isLoading: newsLinksLoading,
    error: newsLinksError,
  } = useGetFooterNewsLinksQuery();

  // const location = useLocation();
  // const url = `https://esg-bites.netlify.app${location.pathname}`;
  // const [copied, setCopied] = useState(false);
  // const qoute = "ESG Bites";

  const onSubmit = (data) => {
    let newData;
    if (data.mail2) {
      newData = {
        email: data.mail2,
      };
    } else {
      newData = {
        email: data.mail,
      };
    }
    fetch(`${Base_url}/newsletter/subscribe`, {
      method: "POST",
      body: JSON.stringify(newData),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        let message = ""
        if (data.status_code === 200) {
          message = "Thank you for subscribing!";
        } else {
          message = "This email is already subscribed";
        }
        setSuccessMessage(message)
        setTimeout(() => {
          setSuccessMessage(null);
        }, 3000);
      })
      .catch((error) => {
        console.error("API request error: " + error);
      });
  };

  React.useEffect(() => {
    if (width < 768) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }, [width]);
  const socialLinks = [
    {
      img: fb,
      link: "https://m.facebook.com/profile.php/?id=61555808926669&name=xhp_nt__fb__action__open_user",
    },
    {
      img: twitter,
      link: "https://twitter.com/ESG_Bites",
    },
    {
      img: insta,
      link: "https://www.instagram.com/esgbites?igsh=ZWMyNDBlOTQyNg==",
    },
    {
      img: linkedin,
      link: "https://www.linkedin.com/company/esgbites/",
    },
  ];

  // const linkItems = [
  //   {
  //     text: "About us",
  //     link: "about",
  //   },
  //   {
  //     text: "Terms & Conditions",
  //     link: "terms",
  //   },
  //   {
  //     text: "Privacy Policy",
  //     link: "privacy-policy",
  //   },
  //   {
  //     text: "Contact",
  //     link: "contact",
  //   },
  // ];
  // const newsLinks = [
  //   {
  //     text: "News",
  //     link: "blog",
  //   },
  //   {
  //     text: "Events",
  //     link: "events",
  //   },
  //   {
  //     text: "Sustainability Dictionary",
  //     link: "glossary",
  //   },
  //   {
  //     text: "White Papers",
  //     link: "blog/category?category=white%20papers",
  //   },
  // ];

  return (
    <footer className={`${styles.footer}`}>
      <div className={`${styles.footerContainer} mainContainer`}>
        <div className={styles.aboutContainer}>
          <div className={styles.footerLogo}>
            <Link to="/" style={{ marginRight: "-12px" }}>
              <img src={purple_b} alt="logo" className={styles.logo_b} />
            </Link>
            <Link to="/">
              <img src={purple_name} alt="logo" className={styles.logo_name} />
            </Link>
          </div>
          {!mobile && (
            <div className={styles.joinNewsletter}>
              <p className={styles.newsMesg}>
                Join our mailing list for the latest ESG news!
              </p>
              <form
                className={`${styles.emailArea} formValidation`}
                onSubmit={handleSubmit(onSubmit)}
              >
                <input
                  placeholder="Enter your email"
                  {...register("mail", {
                    required: "Email Address is required",
                    pattern: {
                      value: /^[a-z0-9]+@[a-z0-9]+\.[a-z]{2,4}$/,
                      message: "Please enter a valid email",
                    },
                  })}
                  className={styles.input}
                  aria-invalid={errors.mail ? "true" : "false"}
                  style={{ borderColor: errors.mail && "red" }}
                />
                {errors.mail ? (
                  <p role="alert" className="error">
                    {errors.mail.message}
                  </p>
                ) : (
                  <p className="successMsg">{successMessage}</p>
                )}
                <button type="submit">Yes I'm in</button>
              </form>
            </div>
          )
          }
        </div>
        <div className={styles.linksSection}>
          <div className={styles.linkiconContainer}>
            {footerLinksData?.map((e, i) => (
              <div className={styles.footerSection} key={i}>
                <Link to={`/${e?.link}`}>{e?.text}</Link>
              </div>
            ))}
          </div>
        </div>
        <div className={styles.linksSection}>
          <div className={styles.linkiconContainer}>
            {newsLinksData?.map((e, i) => (
              <div className={styles.footerSection} key={i}>
                <Link to={`/${e?.link}`}>{e?.text}</Link>
              </div>
            ))}
          </div>
        </div>
        
        {mobile && (
          <div className={styles.mobileJoinNewsletter}>
            <p className={styles.newsMesg}>
            Join our mailing list for the latest ESG news!
            </p>
            <form
              className={`${styles.emailArea} formValidation`}
              onSubmit={handleSubmit(onSubmit)}
            >
              <input
                placeholder="Enter your email"
                {...register("mail2", {
                  required: "Email Address is required",
                  pattern: {
                    value: /^[a-z0-9]+@[a-z0-9]+\.[a-z]{2,4}$/,
                    message: "Please enter a valid email",
                  },
                })}
                aria-invalid={errors.mail2 ? "true" : "false"}
                style={{ borderColor: errors.mail2 && "red" }}
              />
              {errors.mail2 ? (
                <p role="alert" className="error">
                  {errors.mail2.message}
                </p>
              ) : (
                <p className="successMsg">{successMessage}</p>
              )}
              <button type="submit">Yes I'm in</button>
            </form>
          </div>
        )}

        <div className={styles.socialMediaContainer}>
          <p className={styles.aboutTitle}>Connect with us on</p>
          <div className={styles.connect}>
          {
            socialLinks?.map((e, i)=>(
            <a href={e.link} target="__blank" rel="noreferrer noopener" key={i}>
              <img className={styles.socialMediaLink} alt="" src={e.img} />
            </a>
            ))
          }
          </div>
        </div>
        
      </div>
      <div className={styles.copyrigthContainer}>© 2023-2025 ESG BITES</div>
      <p style={{ fontSize: '15px', color: '#fff', textDecoration: 'none' }}>
                    <a href="https://www.banao.tech/" style={{ fontSize: 'inherit', color: 'inherit', textDecoration: 'inherit' }}>
                        Visit Banao
                    </a>
                </p>
    </footer>
  );
};

export default Footer;
