import { Route,Routes } from 'react-router-dom';
import './App.css';
import {lazy, Suspense} from 'react';
import Spinner from './components/Spinner/Spinner';
import Unsubscribe from './components/Unsubscribe/Unsubscribe';
import { useGetAboutUsQuery } from './redux/api/homeApi';

const Home = lazy(() => import('./pages/Home'));
const Blog = lazy(() => import('./pages/Blog'));
const BlogDetails = lazy(() => import('./pages/BlogDetails'));
const Category = lazy(() => import('./pages/Category'));
const Tag = lazy(() => import('./pages/Tag'));
const Companies = lazy(() => import('./pages/Companies'));
const Contact = lazy(() => import('./pages/ContactPage'));
const Events = lazy(() => import('./pages/Events'));
const About = lazy(() => import('./pages/About'));
const SearchResult = lazy(() => import('./pages/SearchResult'));
const EventDetail = lazy(() => import('./pages/EventDetail'));
const TermsPage = lazy(() => import('./pages/TermsPage'));
const PrivacyPage = lazy(() => import('./pages/PrivacyPage'));
const GlossaryPage = lazy(() => import('./pages/GlossaryPage'));

const App =() => {
  const {
    data: aboutUsData,
    isLoading: aboutUsLoading,
    error: aboutUsError,
  } = useGetAboutUsQuery()
  return aboutUsLoading ? <Spinner/> :(
    <>
      <Routes>
        <Route element={<Suspense fallback={<Spinner/>}>
          <Home />
        </Suspense>} path='/' />

        <Route element={<Suspense fallback={<Spinner/>}>
          <Blog />
        </Suspense>} path='/blog' />
        <Route element={<Suspense fallback={<Spinner/>}>
          <Contact />
        </Suspense>} path='/contact' />
          
          <Route element={<Suspense fallback={<Spinner/>}>
          <BlogDetails />
        </Suspense>} path='/blog/:id' /> 
          
          <Route element={<Suspense fallback={<Spinner/>}>
          <Category />
        </Suspense>} path='/blog/category' />

        <Route element={<Suspense fallback={<Spinner/>}>
          <Tag />
        </Suspense>} path='/blog/tag' />
          
        <Route element={<Suspense fallback={<Spinner/>}>
          <Companies />
        </Suspense>} path='/companies' />

        <Route element={<Suspense fallback={<Spinner/>}>
          <Events />
        </Suspense>} path='/events' />

        {
          aboutUsData?.visibility && <Route element={<Suspense fallback={<Spinner/>}>
            <About />
          </Suspense>} path='/about' />
        }

        <Route element={<Suspense fallback={<Spinner/>}>
          <SearchResult />
        </Suspense>} path='/blogs/universal-search' />

        <Route element={<Suspense fallback={<Spinner/>}>
          <EventDetail />
        </Suspense>} path='/events/:id' />
          
        <Route element={<Suspense fallback={<Spinner/>}>
          <TermsPage />
        </Suspense>} path='/terms' />

        <Route element={<Suspense fallback={<Spinner/>}>
          <PrivacyPage />
        </Suspense>} path='/privacy-policy' />

        <Route element={<Suspense fallback={<Spinner/>}>
          <GlossaryPage />
        </Suspense>} path='/glossary' />
        <Route element={<Suspense fallback={<Spinner/>}>
          <Unsubscribe />
        </Suspense>} path='/unsubscribe' />
      </Routes>
    </>
  );
}

export default App;
