import React,{ useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import privacy from "../../helpers/privacy";
import { SubscriberSec } from "../index";
import styles from "./privacy.module.css";
import SEO from "../SEO/SEO";

const Privacy = ({ title }) => {
  const [active, setActive] = React.useState("");
  const [scrolledUp, setScrolledUp] = React.useState(false);
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [currentScrollPos, setCurrentScrollPos] = React.useState(0);
  const location = useLocation()
  const handleClick = (id) => () => {
    scrolledUp && setScrolledUp(false);
    setActive(id);
  };

  useEffect(() => {
    if (active) {
      // get scroll position of element
      const element = document.getElementById(active);
      const position = element.getBoundingClientRect();
      const offset = window.pageYOffset;
      const scroll = position.top + offset;
      setScrollPosition(scroll);
    }
  }, [active]);
  // using scrolled position detect when user scrolls up

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      if (currentScrollPos < scrollPosition) {
        setScrolledUp(true);
      } else {
        setScrolledUp(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);

  }, [scrollPosition]);

  useEffect(() => {
    // get realtime scroll position
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setCurrentScrollPos(currentScrollPos);

    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll",handleScroll);
    
  }, []);



  const tableContent = [
    {
      title: "Interpretations and Definitions",
      link: "interpretations"
    },
    {
      title: "Collecting and Using Your Personal Data",
      link: "collectingdata"
    },
    {
      title: "Detailed Information on the Processing of Your Personal Data",
      link: "processingdata"
    },
    {
      title: "GDPR Privacy",
      link: "gdprprivacy"
    },
    {
      title: "CCPA/CPRA Privacy Notice (California Privacy Rights)",
      link: "californiaprivacy"
    },
    {
      title: "\"Do Not Track\" Policy as Required by California Online Privacy Protection Act (CalOPPA)",
      link: "donottrack"
    },
    {
      title: "Your California Privacy Rights (California's Shine the Light law)",
      link: "shinethelightlaw"
    },
    {
      title: "California Privacy Rights for Minor Users (California Business and Professions Code Section 22581)",
      link: "minorusers"
    },
    {
      title: "Children's Privacy",
      link: "childrensprivacy"
    },
    {
      title: "Links to Other Websites",
      link: "otherwebsites"
    },
    {
      title: "Changes to this Privacy Policy",
      link: "changestoprivacypolicy"
    },
    {
      title: "Contact Us",
      link: "contact"
    }
  ];

  return (
    <>
      <SEO
        title="Privacy Policy"
        description="Privacy Policy"
        name="Privacy Policy"
        type="Privacy Policy"
      />
      <div className={`${styles.policy} mainContainer`}>
        <div className={styles.policyHeading}>{title}</div>
        {/* <div className={styles.date}>Last updated: 12 March, 2023</div> */}
        <br />
        <div className={styles.main}>
          <div className={styles.right}>
            <div className={styles.subText}>
              Last updated: July 22, 2024
              This Privacy Policy describes Our policies and procedures on the collection, 
              use and disclosure of Your information when You use the Service and tells 
              You about Your privacy rights and how the law protects You.

              We use Your Personal data to provide and improve the Service. By using the 
              Service, You agree to the collection and use of information in accordance 
              with this Privacy Policy.
            </div>
            <div className={styles.mobileTableMain}>
            <div className={styles.mobileTable}>
              <div className={styles.mobileTableTitle}>
              Table of Contents
              </div>
              <div className={styles.mobileTableItems}>
                {
                  tableContent?.map((e,i)=>(
                    <div className={styles.mobileTableItem} onClick={handleClick(e.link)}>
                    <a href={`#${active}`}>
                      {i + 1}. <span>{e.title}</span>
                    </a>
                    </div>
                  ))
                }
              </div>
            </div>
            </div>
            {privacy.map((e, i) => (
              <div
                className={`${styles.item} ${
                  e.id === active? styles.custMt:''} ${ scrolledUp
                    && styles.noMt}`}
                key={i}
                id={e.id}
              >
                <div className={styles.title}>{`${i + 1}. ${e?.title}`}</div>
                <div className={styles.text}>
                  {e?.subpoint?.length !== 0
                    ? e?.subpoint?.map((elem, index) => (
                        <div className={styles.subItem} key={index}>
                          <div className={styles.subItemTitle}>{`${i + 1}.${
                            index + 1
                          } ${elem?.stitle}`}</div>
                          <div className={styles.subItemText}>
                            {elem?.stext}
                          </div>
                        </div>
                      ))
                    : e?.text}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.left}>
            <div className={styles.leftContainer}>
              <div className={styles.tableHeading}>Table of Contents</div>
              <div className={styles.tableItems}>
                {tableContent?.map((e, i) => (
                  <div
                    className={styles.tableItem}
                    key={i}
                    onClick={handleClick(e.link)}
                    style={{backgroundColor : location?.hash === `#${e?.link}` && "#EDF2F7"}}
                  >
                    {
                      e.link === "events" ? <Link to="/events" className={styles.link}>Events</Link>: <a href={`#${active}`}>{e?.title}</a>
                    }
                   
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <SubscriberSec />
      </div>
    </>
  );
};

export default Privacy;
